import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('../page/home')
const SelectCity = () => import('../page/selectCity')
const SelectResult = () => import('../page/selectResult')

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: '诚邀你加入小鹏汽车鹏友会'
        }
    },
    {
        path: '/selectCity',
        name: 'selectCity',
        component: SelectCity,
        meta: {
            title: '诚邀你加入小鹏汽车鹏友会'
        }
    },
    {
        path: '/selectResult',
        name: 'selectResult',
        component: SelectResult,
        meta: {
            title: '诚邀你加入小鹏汽车鹏友会'
        }
    }
]

const router = new VueRouter({
    // mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

Vue.use(VueRouter)

export default router
