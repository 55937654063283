<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {  
     // 创建cnzz统计js
    const script = document.createElement('script')  
    script.src = `https://s9.cnzz.com/z_stat.php?id=1280222385&web_id=1280222385`  
    script.language = 'JavaScript'  
    document.body.appendChild(script)  
  },  
  watch: {  
    '$route': {
      handler() {
        setTimeout(() => { //避免首次获取不到window._czc
          if (window._czc) {  
            let location = window.location; 
            let contentUrl = location.pathname + location.hash;  
            let refererUrl = '/';  
            // 用于发送某个URL的PV统计请求，
            window._czc.push(['_trackPageview', contentUrl, refererUrl])  
          }
        }, 300) 
      },
      immediate: true  // 首次进入页面即执行
    }  
  }
}
</script>

<style>
@font-face {
  font-family: 'PuHuiTi';
  src: url('assets/fonts/Alibaba-PuHuiTi-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}
*{
  font-family: 'PuHuiTi';
  font-weight: 400;
}
body {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: rgb(224, 249, 254);
}
</style>